import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import { SUBMIT_CONTACT_FORM } from '../../graphql/mutations/forms';

const initialFormState = {
  name: '',
  email: '',
  course: '',
  message: ''
};

export const ContactForm = () => {
  const [email, setEmail] = useState();
  const [formFields, setFormFields] = useState(initialFormState);
  const [formState, setFormState] = useState('');
  const [submitContactForm] = useMutation(SUBMIT_CONTACT_FORM);

  const onChange = (e, field) => {
    setFormFields({
      ...formFields,
      [field]: e.target.value,
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setFormState('loading');

    try {
      const is_valid_email = formFields.email.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);

      if (!is_valid_email) {
        alert('Please enter a valid email.');
        setFormState('error');
        return;
      }

      fetch('https://api.developyn.com/public/contact-service/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'x-api-key': '4c5cfd6ed96c66db82a46f7d739ba7a34ab2647465a0381aaf2ea65652c1bcb3'
        },
        body: JSON.stringify({
          id: '1zzzMXbpGt36ZMT3qOHEYRSLmAm',
          name: formFields.name,
          course: formFields.course,
          email: formFields.email,
          message: formFields.message
        })
      }).then(
        succ => {
          succ.json().then(data => console.log(data));
          setFormState('success');
        },
        err => setFormState('error')
      );
    } catch (e) {
      console.log(e);
      setFormState('error');
    }

  }

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <label htmlFor="name">
        <span className="1.25">Name*</span>
      </label>
      <input
        name="name"
        className="w-input mb-1.5"
        onChange={e => onChange(e, 'name')}
        placeholder="Your name"
        required
      />

      <label htmlFor="email">
        <span className="1.25">Email*</span>
      </label>
      <input
        name="email"
        className="w-input mb-1.5"
        onChange={e => onChange(e, 'email')}
        placeholder="Your email address"
        required
      />

      <label htmlFor="course">
        <span className="1.25">Course*</span>
      </label>
      <input
        name="course"
        className="w-input mb-1.5"
        onChange={e => onChange(e, 'course')}
        placeholder="The subject you would like to contact me about"
        required
      />

      <label htmlFor="message">
        <span className="1.25">Message*</span>
      </label>
      <textarea
        name="message"
        className="w-input mb-1.5"
        onChange={e => onChange(e, 'message')}
        placeholder="Your enquiry"
        rows="10"
        required
      />

      <button disabled={formState === 'loading'} type="submit" className="button-blue w-button">
        {formState === 'loading' ? 'Working on it...' : 'Submit form'}
      </button>
    </form>
  );

  const renderThanksMessage = () => (
    <p>
      Thanks for submitting your message {formFields.name}! I will get back to you as soon as I
      can.
    </p>
  );

  return formState === 'success' ? renderThanksMessage() : renderForm();
};
